footer {
    width: 100%;
    background-color:rgb(51, 51, 51);
    color: white;
    padding: 2rem 0;
    .image-logo {
        height: 2rem;
        width: auto;
    }
}
.toc-static nav:first-of-type {
    position: static !important;
}
.toc-fixed nav:first-of-type {
    position: fixed !important;
}